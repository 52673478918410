import axios from 'axios';
import { toast } from 'react-toastify';

// Configura la base de la URL y otras configuraciones
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_URL_API; // URL base de tu API

const API_URL = process.env.REACT_APP_URL_API;
// console.log('API_URL :>> ', API_URL);

// Variable para controlar si ya se redirigió a /login
let hasRedirectedToLogin = false;

// Configura el interceptor de respuesta de Axios para manejar errores 401
axios.interceptors.response.use(
  (response) => {
    // Si la respuesta es exitosa, simplemente la retornamos
    return response;
  },
  (error) => {
    // Verifica si la respuesta tiene un código de estado 401
    if (error.response && error.response.status === 401) {
      // Verifica si ya estamos en la página de login
      if (!hasRedirectedToLogin && window.location.pathname !== '/login') {
        toast.error('Tu sesión ha expirado. Por favor, inicia sesión nuevamente.', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });

        // Evita múltiples redirecciones
        hasRedirectedToLogin = true;

        // Redirige al usuario al login
        window.location.href = '/login';
      }

      // Retorna un objeto vacío o null para evitar que el código intente procesar datos inexistentes
      return Promise.resolve({ data: null });
    }

    // Para otros errores, puedes seguir lanzando el error
    return Promise.reject(error);
  }
);

// Funciones de API
export const getRoles = async () => {
  const response = await axios.get(`${API_URL}roles`);
  return response.data || []; // Asegura que siempre se devuelva un array
};

export const createRole = async (roleData) => {
  const response = await axios.post(`${API_URL}roles`, roleData);
  return response.data || {};
};

export const updateRole = async (id, roleData) => {
  const response = await axios.put(`${API_URL}roles/${id}`, roleData);
  return response.data || {};
};

export const deleteRole = async (id) => {
  const response = await axios.delete(`${API_URL}roles/${id}`);
  return response.data || {};
};

export const getApps = async () => {
  const response = await axios.get(`${API_URL}apps`);
  return response.data || [];
};





export const getAppsByNotes = async (userId) => {


  const response = await axios.get(`${API_URL}appsbynotes`, {
    params: { userId }
  });
  return response.data || [];
};




export const getAppsByFees = async (userId) => {


  const response = await axios.get(`${API_URL}appsbyfees`, {
    headers: {
      'userid': userId // Aquí se agrega el userId en los headers
    }
  });
  
  return response.data || [];
};




export const createFees = async (feesData) => {


  const response = await axios.post(`${API_URL}fees_core`, feesData);
  return response.data || {};
};

export const updateFees = async (id, feesData) => {
  const response = await axios.put(`${API_URL}fees_core/${id}`, feesData);
  return response.data || {};
};








export const createApp = async (roleData) => {
  const response = await axios.post(`${API_URL}apps`, roleData);
  return response.data || {};
};

export const updateApp = async (id, roleData) => {
  const response = await axios.put(`${API_URL}apps/${id}`, roleData);
  return response.data || {};
};

export const deleteApp = async (id) => {
  const response = await axios.delete(`${API_URL}apps/${id}`);
  return response.data || {};
};

export const getFunctionalities = async () => {
  const response = await axios.get(`${API_URL}functionalities`);
  return response.data || [];
};


export const getFunctionalitiesSearch = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}functionalities`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};



export const createFunctionality = async (functionalityData) => {
  const response = await axios.post(`${API_URL}functionalities`, functionalityData);
  return response.data || {};
};

export const updateFunctionality = async (id, functionalityData) => {
  const response = await axios.put(`${API_URL}functionalities/${id}`, functionalityData);
  return response.data || {};
};

export const deleteFunctionality = async (id) => {
  const response = await axios.delete(`${API_URL}functionalities/${id}`);
  return response.data || {};
};

export const getUsersRoles = async () => {
  const response = await axios.get(`${API_URL}userroles`);
  return response.data || [];
};

export const createUserRoles = async (roleData) => {
  const response = await axios.post(`${API_URL}userroles`, roleData);
  return response.data || {};
};

export const updateUserRoles = async (id, roleData) => {
  const response = await axios.put(`${API_URL}userroles/${id}`, roleData);
  return response.data || {};
};

export const deleteUserRoles = async (id) => {
  const response = await axios.delete(`${API_URL}userroles/${id}`);
  return response.data || {};
};

export const getRolesForUser = async (id) => {
  const response = await axios.get(`${API_URL}userroles/${id}`);
  return response.data || [];
};

// Nueva función para obtener todos los usuarios sin paginación
export const getAllUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}users/all`);
    // console.log('API response for all users:', response.data);
    const users = Array.isArray(response.data) ? response.data : response.data?.users || [];
    return users;
  } catch (error) {
    console.error('Error fetching all users:', error);
    return []; // Retorna un array vacío en caso de error
  }
};

export const getUsers = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}users`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};

// Funciones de API
export const getUser = async (id) => {
  const response = await axios.get(`${API_URL}users/${id}`);
  return response.data || {};
};

export const createUser = async (roleData) => {
  const response = await axios.post(`${API_URL}users`, roleData);
  return response.data || {};
};

export const updateUser = async (id, roleData) => {
  const response = await axios.put(`${API_URL}users/${id}`, roleData);
  return response.data || {};
};

export const deleteUser = async (id) => {
  const response = await axios.delete(`${API_URL}users/${id}`);
  return response.data || {};
};

export const getCodesFor = async (type) => {
  const response = await axios.get(`${API_URL}codesfor/${type}`);
  return response.data || [];
};







export const getAllUserAddresses = async ({userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}user_addresses/all/${userId}`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};


/*

// Servicios para gestionar direcciones de usuario
export const getAllUserAddresses = async (userId) => {  // Id de usuario obtener su address

  // console.log("obtiene los address");
  const response = await axios.get(`${API_URL}user_addresses/all/${userId}`);
  return response.data || [];
};

*/








export const getUserAddresses = async (userId) => {   //  Id de address
  const response = await axios.get(`${API_URL}user_addresses/${userId}`);
  return response.data || [];
};

export const createUserAddress = async (userId, addressData) => {
  const response = await axios.post(`${API_URL}user_addresses`, addressData);
  return response.data || {};
};

export const updateUserAddress = async (addressId, addressData) => {
  const response = await axios.put(`${API_URL}user_addresses/id/${addressId}`, addressData);
  return response.data || {};
};

export const deleteUserAddress = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_addresses/${addressId}`);
  return response.data || {};
};

export const deleteUserAddressId = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_addresses/id/${addressId}`);
  return response.data || {};
};

export const getUserDocs = async (userId) => {
  const response = await axios.get(`${API_URL}user_docs/${userId}`);
  return response.data || [];
};

export const createUserDocs = async (userDocs) => {
  const response = await axios.post(`${API_URL}user_docs`, userDocs);
  return response.data || {};
};



export const updateUserDocsId = async (addressId, userDocs) => {
  const response = await axios.put(`${API_URL}user_docs/id/${addressId}`, userDocs);
  return response.data || {};
};

export const updateUserDocs = async (addressId, userDocs) => {
  const response = await axios.put(`${API_URL}user_docs/${addressId}`, userDocs);
  return response.data || {};
};

export const deleteUserDocs = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_docs/${addressId}`);
  return response.data || {};
};

// Funciones de API
export const getCountries = async () => {
  const response = await axios.get(`${API_URL}countries`);
  return response.data || [];
};








// Apps


export const getAllUserApps = async ({userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userapps/all/${userId}`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};




export const getUserApp = async (userId) => {   //  Id de address
  const response = await axios.get(`${API_URL}userapps/${userId}`);
  return response.data || [];
};



export const createUserApp = async (userId, addressData) => {
  const response = await axios.post(`${API_URL}userapps`, addressData);
  return response.data || {};
};



export const updateUserApp = async (addressId, addressData) => {
  const response = await axios.put(`${API_URL}userapps/id/${addressId}`, addressData);
  return response.data || {};
};

/*
export const deleteUserApp = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_addresses/${addressId}`);
  return response.data || {};
};

*/

export const deleteUserAppId = async (addressId) => {
  const response = await axios.delete(`${API_URL}userapps/id/${addressId}`);
  return response.data || {};
};














// Docs


export const getAllUserDocs = async ({userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}user_docs/all/${userId}`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};


export const getUserDoc = async (userId) => {   //  Id de address
  const response = await axios.get(`${API_URL}user_docs/${userId}`);
  return response.data || [];
};



export const createUserDoc = async (userId, addressData) => {

// console.log(addressData,'addressDatazzzzz');

  const response = await axios.post(`${API_URL}user_docs`, addressData);
  return response.data || {};
};



export const updateUserDoc = async (addressId, addressData) => {
  const response = await axios.put(`${API_URL}user_docs/id/${addressId}`, addressData);
  return response.data || {};
};

/*
export const deleteUserDoc = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_docs/${addressId}`);
  return response.data || {};
};

*/


export const deleteUserDocId = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_docs/id/${addressId}`);
  return response.data || {};
};


// Roles







export const getRolesAllForUser = async ({userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};


export const getUserRole = async (userId) => {   //  Id de address
  const response = await axios.get(`${API_URL}userroles/${userId}`);
  return response.data || [];
};


export const getUsersRolesSearch = async ({ search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};

export const createUserRole = async (userId, addressData) => {
  const response = await axios.post(`${API_URL}userroles`, addressData);
  return response.data || {};
};



export const updateUserRole = async (addressId, addressData) => {
  const response = await axios.put(`${API_URL}userroles/id/${addressId}`, addressData);
  return response.data || {};
};

/*
export const deleteUserRole = async (addressId) => {
  const response = await axios.delete(`${API_URL}user_addresses/${addressId}`);
  return response.data || {};
};

*/


export const deleteUserRoleId = async (addressId) => {
  const response = await axios.delete(`${API_URL}userroles/id/${addressId}`);
  return response.data || {};
};


//  Notes 

// Funciones de API
export const getNotes = async () => {
  const response = await axios.get(`${API_URL}roles`);
  return response.data || []; // Asegura que siempre se devuelva un array
};


export const getNotesForUser = async (id) => {
  const response = await axios.get(`${API_URL}userroles/${id}`);
  return response.data || [];
};




export const getNotesAllForUser = async ({userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};





export const getNotesByAppList = async (appId, userId) => {
  try {
      const response = await axios.get(`${API_URL}users/notesbyapp`, {
          headers: {
              app: appId,
              userid: userId
          }
      });  // Asegúrate de que este cierre sea correcto
      return response.data;
  } catch (error) {
      throw new Error('Error fetching notes by app: ' + error.message);
  }
};


/*

export const getNotesByAppList = async (app, userid) => { const response = await axios.get(`${API_URL}users/notesbyapp`);
  return response.data || [];
};

*/


// News


// Funciones de API
export const getNews = async () => {
  const response = await axios.get(`${API_URL}roles`);
  return response.data || []; // Asegura que siempre se devuelva un array
};


export const getNewsForUser = async (id) => {
  const response = await axios.get(`${API_URL}userroles/${id}`);
  return response.data || [];
};




export const getNewsAllForUser = async ({userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};



export const getNewsByAppList = async (appId, userId) => {
  try {
      const response = await axios.get(`${API_URL}users/newsbyapp`, {
          headers: {
              app: appId,
              userid: userId
          }
      });  // Asegúrate de que este cierre sea correcto
      return response.data;
  } catch (error) {
      throw new Error('Error fetching notes by app: ' + error.message);
  }
};

//  Compliance



// Funciones de API
export const getCompliance = async () => {
  const response = await axios.get(`${API_URL}roles`);
  return response.data || []; // Asegura que siempre se devuelva un array
};


export const getComplianceForUser = async (id) => {
  const response = await axios.get(`${API_URL}userroles/${id}`);
  return response.data || [];
};




export const getComplianceAllForUser = async ({userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};






// Funciones de API
export const getFees = async () => {
  const response = await axios.get(`${API_URL}roles`);
  return response.data || []; // Asegura que siempre se devuelva un array
};


export const getFeesForUser = async (id) => {
  const response = await axios.get(`${API_URL}userroles/${id}`);
  return response.data || [];
};




export const getFeesAllForUser = async ({userId, search = '', page = 1, limit = 10 }) => {
  try {
    const response = await axios.get(`${API_URL}userroles/all/${userId}`, {
      params: { search, page, limit }
    });
    // console.log('API response:', response.data);
    return {
      users: response.data || [],
      totalPages: response.headers['x-total-pages'] || Math.ceil((response.data?.total || 0) / limit)
    };
  } catch (error) {
    console.error('Error fetching users:', error);
    return { users: [], totalPages: 0 };
  }
};

// Funciones de API
export const getMethods = async () => {
  const response = await axios.get(`${API_URL}methods`);
  return response.data || [];
};


// Funciones de API
export const getConcepts = async () => {
  const response = await axios.get(`${API_URL}concepts`);
  return response.data || [];
};



export const getFeesByAppList = async (appId, userId) => {
  try {
      const response = await axios.get(`${API_URL}users/feesbyapp`, {
          headers: {
              app: appId,
              userid: userId
          }
      });  // Asegúrate de que este cierre sea correcto
      return response.data;
  } catch (error) {
      throw new Error('Error fetching notes by app: ' + error.message);
  }
};